/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import * as React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Polityka prywatności | Fair Fur</title>
    <meta name="description" content="" />
    <link rel="canonical" href="https://fairfur.pl" />
  </>
);

const Main = styled.main`
  margin-bottom: 0;
  padding: 100px 0;

  ol {
    padding: 0 0 0 1rem
  }
`;

const Terms = styled.section`
  max-width: 800px;
  margin: 0 auto;

  h1 {
    text-align: center;
  }

  a {
    color: #78569d;
  }

  .list-privacy {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;
    }

    > li {
      margin: 0 0 75px;
    }

    ul {
      list-style: disc;
      margin: 0 0 2rem;
    }
  }

  .policy-heading {
    display: flex;
    flex-wrap: wrap;

    h2, p {
      text-align: center;
      width: 100%;
    }

    h2 {
      font-size: 2.6rem;
      line-height: 120%;
      order: 1;
    }

    p {
      font-size: 1.8rem;
      margin: 0 0 4px;
    }
  }
`;

const PrivacyPolicy = () => (
  <Main>
    <Terms className="row">
      <h1>Regulamin konkursu na Facebook oraz&nbsp;Instagram</h1>

      <ul className="list-privacy">
        <li>
          <div className="policy-heading">
            <h2>Postanowienia ogólne</h2>
            <p>§1</p>
          </div>

          <ol>
            <li>
              Femi Trade sp. z o.o. z siedzibą w Warszawie, przy ul. Vogla 2a (02-963 Warszawa), zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000893840 (dalej jako: „Organizatorzy”) są organizatorem konkursu internetowego pt. „ZIMOWE SZALEŃSTWO” (zwanego dalej „Konkursem”).
            </li>

            <li>Niniejszy Regulamin Konkursu (dalej „Regulamin”) określa warunki przeprowadzenia i rozstrzygnięcia Konkursu.</li>
            <li>Udział w Konkursie jest możliwy za pośrednictwem serwisu Instagram.</li>
            <li>Fundatorem nagrody jest Organizator.</li>
            <li>Pracownicy Organizatorów oraz członkowie ich rodzin nie mogą brać udziału w Konkursie. Przez członków rodziny rozumie się: wstępnych, zstępnych, rodzeństwo, małżonków i osoby pozostające w stosunku przysposobienia.</li>
            <li>Konkurs nie jest stworzony, administrowany ani sponsorowany przez serwis META.</li>
          </ol>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Warunki uczestnictwa w&nbsp;Konkursie i&nbsp;jego przebieg</h2>
            <p>§2</p>
          </div>

          <ol>
            <li>
              W Konkursie mogą brać udział osoby fizyczne, pełnoletnie posiadające pełną zdolność do czynności prawnych i zamieszkałe na terenie Polski, które spełniają wszystkie postanowienia, warunki i wymagania przewidziane niniejszym Regulaminem (dalej „Uczestnik”)
            </li>
            <li>Warunkiem udziału w konkursie jest: posiadanie konta w serwisie społecznościowym Instagram, like pod postem, udostępnienie postu na swoim stories, udostępnienie zdjęcia z oznaczeniem profilu fair fur, komentarz z oznaczeniem 3 innych kont, zapoznanie się z treścią Regulaminu oraz przyjęcie do wiadomości jego postanowień. Umieszczane komentarze nie może naruszać jakichkolwiek praw osób trzecich, w szczególności praw autorskich innych twórców.</li>
            <li>Konkurs trwa od dnia 16.01.2024 od godz. 8:00 do końca dnia 23.01.2024..</li>
            <li>Zdjęcie  umieszczone w serwisie Instagram przez Uczestnika Konkursu nie może zawierać jakichkolwiek przekazów promocyjnych ani reklamowych m.in. towarów, usług, znaków towarowych i/lub podmiotów poza towarami i znakami towarowymi marek należących do Organizatorów.</li>
            <li>W przypadku wygrania nagrody, dane Uczestnika konieczne do wysłania mu wygranej nagrody zostaną ustalone przez wiadomość prywatną w serwisie Instagram, w okresie 5 dni roboczych od wiadomości z informacją o wygranej, która zostanie przekazana za pośrednictwem serwisu Instagram. W przypadku wygranej, Uczestnik Konkursu udostępni Organizatorowi: imię, nazwisko, numer telefonu kontaktowego oraz adres korespondencyjny, na który ma zostać wysłana nagroda.</li>
            <li>Wyniki konkursu zostaną̨ opublikowane 26.01.2024  roku o godzinie 20:00 w relacji konta @fairfur</li>
            <li>Uczestnik Konkursu:
              <ol style={{ margin: '10px 20px 20px' }}>
                <li>poprzez udział w konkursie oświadcza, że zapoznał się z jego treścią i akceptuje wszystkie warunki udziału w Konkursie oraz treść Regulaminu, a także wyraża zgodę̨ na wykorzystywanie przez Organizatorów swoich danych osobowych, w tym imienia, nazwiska oraz danych kontaktowych niezbędnych do zrealizowania wysyłki.</li>
                <li>oświadcza i gwarantuje, że jest autorem przygotowanej treści komentarza oraz nie narusza on jakichkolwiek praw osób trzecich, w szczególności praw autorskich innych twórców.</li>
                <li>Uczestnik zobowiązuje się zwolnić Organizatora Konkursu od odpowiedzialności wobec osób trzecich</li>
              </ol>
            </li>
            <li>Całkowicie zabronione jest i stanowi naruszenie Regulaminu zgłaszanie w Konkursie treści sprzecznych z prawem lub dobrymi obyczajami, a w szczególności treści pornograficznych, zniesławiających, obrażających uczucia religijne, wzywających do nienawiści rasowej, etnicznej, wyznaniowej, propagujących naruszenie prawa, upowszechniających techniki łamania zabezpieczeń danych lub złośliwe oprogramowanie oraz wszelkich innych, podobnych w treści i działaniu materiałów.</li>
            <li>Organizator nie ponosi odpowiedzialności za jakiekolwiek zakłócenia w działaniu łączy teleinformatycznych, serwerów, interfejsów, przeglądarek oraz serwisu Facebook i Instagram.</li>
          </ol>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Nagrody</h2>
            <p>§3</p>
          </div>

          <ol>
            <li>Za wydanie nagród w Konkursie odpowiadają Organizatorzy.</li>
            <li>
              <p>Nagrodami są:</p>
              <p><em>Zestaw produktów FAIR FUR</em></p>
            </li>
            <li>O przyznaniu nagród uczestnikom Konkursu decyduje wynik Jury Konkursowego.</li>
            <li>Nie ma możliwości zamiany Nagrody na inną nagrodę̨ ani wypłaty jej ekwiwalentu w formie pieniężnej.</li>
            <li>Nagrody zostaną wydane Zwycięzcom na koszt Organizatorów przesyłką pocztową dostarczoną na terenie Polski.</li>
            <li>W celu przekazania Nagród, Zwycięzca w ciągu 5 dni od dnia otrzymania wiadomości o wygranej, powinien przesłać swoje dane osobowe (imię i nazwisko, adres właściwy do przekazania nagrody oraz numer telefonu) w wiadomości prywatnej lub na adres e-mail: <a href="mailto:ania@femi-trade.com">ania@femi-trade.com</a></li>
            <li>Nagroda zostanie przesłana na adres wskazany przez Zwycięzcę w ciągu 7 dni od daty przesłania danych adresowych.</li>
            <li>Organizatorzy nie ponoszą odpowiedzialności za brak możliwości przekazania nagrody z przyczyn leżących po stronie Uczestnika, a w szczególności, w przypadku niepodania bądź podania błędnych danych, zmiany danych Uczestnika, o której nie został poinformowany. W takim przypadku nagroda przepada.</li>
            <li>Organizatorzy nie ponoszą odpowiedzialności za nieprawidłowe dane podane przy rejestracji w serwisie Instagram, w szczególności za zmianę danych osobowych uniemożliwiającą odszukanie Uczestnika, któremu przyznano nagrodę.</li>
            <li>W przypadkach wykrycia działań niezgodnych z Regulaminem, próby wpływania na wyłonienie Zwycięzcy w sposób niedozwolony, w szczególności poprzez zakładanie fikcyjnych profili prywatnych w serwisie Instagram, dany Uczestnik może zostać wykluczony z Konkursu.</li>
          </ol>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Postępowanie reklamacyjne</h2>
            <p>§4</p>
          </div>

          <ol>
            <li>Kontrolę nad prawidłowością przebiegu Konkursu sprawują Organizatorzy.</li>
            <li>Reklamacje związane z Konkursem mogą być kierowane do Organizatorów na adres siedziby wskazany w Regulaminie. Za rozstrzyganie reklamacji odpowiadają Organizatorzy.</li>
            <li>Każda reklamacja powinna zawierać, co najmniej wskazanie jej autora (imię i nazwisko oraz adres do doręczeń), jak również zarzuty związane z udziałem w Konkursie autora reklamacji.</li>
            <li>Zainteresowani uczestnicy, zostaną powiadomieni o rozpatrzeniu reklamacji listem poleconym wysłanym najpóźniej w ciągu 14 dni od daty rozpatrzenia reklamacji przez Organizatorów.</li>
          </ol>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Publikacja Regulaminu Konkursu</h2>
            <p>§5</p>
          </div>

          <p>Z treścią Regulaminu można zapoznać się w relacji wyróżnionej na koncie @fairfur.official w relacji konkurs.</p>
        </li>

        <li>
          <div className="policy-heading">
            <h2>Postanowienia końcowe</h2>
            <p>§6</p>
          </div>

          <ol>
            <li>Wycofanie zgody lub wniesienie sprzeciwu wobec przetwarzania danych osobowych przez Uczestnika w trakcie trwania Konkursu, będzie równoznaczne z wycofaniem się z Konkursu oraz będzie uprawniać Organizatorów do odmowy wydania nagrody.</li>
            <li>W kwestiach nieuregulowanych niniejszym Regulaminem stosuje się przepisy Kodeksu cywilnego i inne przepisy prawa powszechnie obowiązujące.</li>
            <li>Spory odnoszące się i wynikające z Konkursu będą rozwiązywane przez sąd powszechny właściwy miejscowo dla siedziby Organizatorów.</li>
          </ol>
        </li>
      </ul>

      <h2 style={{ fontSize: '3rem', textAlign: 'center', marginTop: '12rem' }}>Klauzula informacyjna</h2>

      <ol>
        <li>Administratorem Danych osobowych uczestników konkursu pod nazwą: Konkurs  są organizatorzy – Femi Trade sp. z o.o. z siedzibą w Warszawie, przy ul. Vogla 2a (02-963 Warszawa), zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000893840 (dalej jako: „Organizatorzy” lub „Administratorzy”).</li>
        <li>W sprawie prawidłowości przetwarzania danych osobowych można skontaktować się z Administratorami za pośrednictwem adresu e-mail: <a href="mailto:ania@femi-trade.com">ania@femi-trade.com</a></li>
        <li>Dane uczestników Konkursu będą przetwarzane wyłącznie w celu i zakresie niezbędnym dla przeprowadzenia Konkursu, to jest w celu weryfikacji uczestnika Konkursu, rozstrzygnięcia Konkursu, wydania nagrody zwycięzcom Konkursu i publicznego podania imienia, nazwiska lub nicku konta na Instagramie.</li>
        <li>Podstawą prawną przetwarzania danych osobowych uczestnika Konkursu jest art. 6 ust. 1 lit a Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) tj. zgoda na przetwarzanie danych osobowych.</li>
        <li>Udzielenie zgody jest dobrowolne, a Uczestnik ma prawo w każdym czasie wycofać swoją zgodę, informując o tym Administratorów drogą mailową na adres wskazany w pkt 2 powyżej lub drogą pisemną na adres siedziby Organizatorów. Cofnięcie zgody nie będzie miało wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</li>
        <li>Dane osobowe Uczestnika mogą być przekazywane innym podmiotom: firmom kurierskim, w zakresie niezbędnym do wysyłki nagrody związanych z przyznaniem nagród. Dane mogą być także przekazywane innym uprawnionym organom, na podstawie stosownych przepisów prawa.</li>
        <li>Dane osobowe Uczestników będą przetwarzane przez Organizatorów do dnia zakończenia Konkursu. Dane osobowe zwycięzców Konkursu będą przetwarzane ponadto do czasu przedawnienia roszczeń Organizatorów i Uczestników związanych z organizacją Konkursu, a ponadto przez okres niezbędny do realizacji zobowiązań podatkowych Organizatorów w związku z wydaniem nagród.</li>
        <li>Dane osobowe Uczestników będą przetwarzane przez Organizatorów do dnia zakończenia Konkursu. Dane osobowe zwycięzców Konkursu będą przetwarzane ponadto do czasu przedawnienia roszczeń Organizatorów i Uczestników związanych z organizacją Konkursu, a ponadto przez okres niezbędny do realizacji zobowiązań podatkowych Organizatorów w związku z wydaniem nagród.</li>
        <li>Uczestnik ma prawo dostępu do treści swoich danych osobowych oraz prawo do ich poprawiania, usunięcia, ograniczenia przetwarzania, przenoszenia danych oraz wniesienia sprzeciwu wobec przetwarzania jego danych, w przypadkach wskazanych przez prawo.</li>
        <li>Dane osobowe uczestnika Konkursu nie będą przetwarzane w sposób zautomatyzowany i nie będą poddawane profilowaniu.</li>
        <li>Uczestnik Konkursu ma prawo wniesienia skargi dotyczącej przetwarzania danych osobowych w związku z organizacją Konkursu do Prezesa Urzędu Ochrony Danych Osobowych.</li>
      </ol>
    </Terms>
  </Main>
);

export const query = graphql`
  query {
    hero: file(relativePath: {regex: "/home\/hero.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
  }`;

export default PrivacyPolicy;
